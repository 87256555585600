import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import { useDrawerContext } from "@/core/context/DrawerContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { RequireOnlyOne } from "@/types/util/RequireOnlyOne"
import {
  DiscoButton,
  DiscoFormControl,
  DiscoIcon,
  DiscoText,
  DiscoTextProps,
} from "@disco-ui"
import DiscoClickToEditInput from "@disco-ui/input/DiscoClickToEditInput"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"

/**
 *  Unlike other static inline elements this does not take in a fragment key
 *  because there are times we use a string as placeholder for the title, such as
 *  default curriculum item tasks. ie. "Add Events to your Calendar"
 */
interface InlineContentTitleSectionStaticProps {
  name: string
  color?: DiscoTextProps["color"]
}

const InlineContentTitleSectionStatic = ({
  name,
  color,
}: InlineContentTitleSectionStaticProps) => {
  return (
    <DiscoText
      color={color}
      testid={`InlineContentTitleSection.static.name`}
      variant={"heading-md"}
      truncateText={2}
    >
      {name}
    </DiscoText>
  )
}

interface InlineContentTitleSectionFormProps {
  form: ContentFormStore
  placeholder?: string
  onSave?: () => Promise<{ success: boolean }>
  focusOnMount?: boolean
  submitButton?: React.ReactNode
}

const InlineContentTitleSectionForm = observer<InlineContentTitleSectionFormProps>(
  ({ form, focusOnMount, submitButton, ...props }) => {
    const isMobile = useIsMobile()
    const { setHideHeaderActions } = useDrawerContext()

    const [showStatic, setShowStatic] = useState(!focusOnMount)
    const showButtons = (!showStatic || form.isChanged) && Boolean(props.onSave)

    const classes = useStyles()

    useEffect(() => {
      setHideHeaderActions(showButtons && !isMobile)
    }, [showButtons, isMobile, setHideHeaderActions])

    if (!showButtons) return <div className={classes.container}>{renderInput()}</div>

    return (
      <form
        id={"InlineContentTitleForm"}
        onSubmit={handleSubmit}
        className={classes.container}
      >
        {renderInput()}
        {renderButtons()}
      </form>
    )

    async function handleSubmit(e: React.FormEvent) {
      e.preventDefault()
      if (!props.onSave) return
      await props.onSave()
    }

    function renderInput() {
      return (
        <DiscoFormControl
          className={classes.titleFormControl}
          errorMessages={form.errorsByField.name}
        >
          <DiscoClickToEditInput
            testid={"InlineContentTitleSection.name"}
            size={"large"}
            truncateText={2}
            value={form.state.content!.name}
            onChange={(v) => (form.state.content!.name = v)}
            placeholder={props.placeholder ?? "Untitled"}
            onStartEdit={() => setShowStatic(false)}
            onStopEdit={() => setShowStatic(true)}
            showInput={showButtons}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={focusOnMount}
          />
        </DiscoFormControl>
      )
    }

    function renderButtons() {
      return (
        <>
          {submitButton && !isMobile ? (
            submitButton
          ) : (
            <DiscoButton
              form={"InlineContentTitleForm"}
              type={"submit"}
              shouldDisplaySpinner={form.isSubmitting}
              classes={{ root: classes.button }}
              testid={"InlineContentTitleSection.save"}
            >
              {isMobile ? <DiscoIcon icon={"check"} /> : "Save"}
            </DiscoButton>
          )}
          <DiscoButton
            onClick={handleCancel}
            color={"grey"}
            variant={"outlined"}
            classes={{ root: classes.button }}
            testid={"InlineContentTitleSection.cancel-button"}
          >
            {isMobile ? <DiscoIcon icon={"close"} /> : "Cancel"}
          </DiscoButton>
        </>
      )
    }

    function handleCancel() {
      form.reset()
    }
  }
)

export type InlineContentTitleSectionProps = RequireOnlyOne<
  {
    form?: ContentFormStore
    name?: string
    placeholder?: string
    onSave?: () => Promise<{ success: boolean }>
    focusOnMount?: boolean
    submitButton?: React.ReactNode
  },
  "form" | "name"
>

function InlineContentTitleSection(props: InlineContentTitleSectionProps) {
  if (props.form)
    return (
      <InlineContentTitleSectionForm
        form={props.form}
        placeholder={props.placeholder}
        onSave={props.onSave}
        focusOnMount={props.focusOnMount}
        submitButton={props.submitButton}
      />
    )
  return <InlineContentTitleSectionStatic name={props.name} />
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    marginTop: theme.spacing(0.75),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  titleFormControl: {
    marginBottom: 0,

    "& > p": {
      marginTop: 0,
    },
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      width: "36px",
      padding: theme.spacing(0, 0.5),
    },
  },
}))

export default observer(InlineContentTitleSection)
