import useDismissNotifications from "@/apps/util/hooks/useDismissNotifications"
import ContentCard, {
  ContentCardProps,
  ContentCardSkeleton,
} from "@/content/detail/ContentCard"
import { PostCardDashboardFragment$key } from "@/content/post/card/__generated__/PostCardDashboardFragment.graphql"
import { PostCardFragment$key } from "@/content/post/card/__generated__/PostCardFragment.graphql"
import { GlobalDrawerParams, useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabel } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import DeletePostButton from "@/post/delete/DeletePostButton"
import EditPostButton from "@/post/edit/EditPostButton"
import PinPostButton from "@/post/pin/PinPostButton"
import UnpinPostButton from "@/post/pin/UnpinPostButton"
import SharePostButton from "@/post/share/SharePostButton"
import FlagContentButton from "@/product/common/page/content/flagging/FlagContentButton"
import ContentFlaggingsChip from "@/product/common/page/content/flagging/popover/ContentFlaggingsChip"
import useCanFlagContent from "@/product/common/page/content/flagging/popover/useCanFlagContent"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import { DiscoButton, DiscoIcon, DiscoLink, DiscoText } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { Theme, useMediaQuery, useTheme } from "@material-ui/core"
import usePermissions from "@utils/hook/usePermissions"
import { setSearchParams } from "@utils/url/urlUtils"
import classNames from "classnames"
import { useFragment } from "react-relay"
import { useRouteMatch } from "react-router-dom"
import { graphql } from "relay-runtime"

interface Props
  extends Pick<
    ContentCardProps,
    "compact" | "hideAttachments" | "hideDescription" | "hideMedia"
  > {
  postKey: PostCardFragment$key
  testid: string
  onPin: VoidFunction
  onUnpin: VoidFunction
  feedId?: GlobalID
  connectionId?: GlobalID
  dashboardKey?: PostCardDashboardFragment$key | null
  isDashboardBlock?: boolean
  isDashboardScrollBlock?: boolean
  clearNotifications?: boolean
  groovyDepths?: Exclude<keyof Theme["palette"]["groovyDepths"], "raisedBoxShadow">
  hideFooterPinButton?: boolean
  hideHeaderPinButton?: boolean
  hideOverflow?: boolean
  hideBookmarkButton?: boolean
  className?: string
  readOnlyReactionButton?: boolean
}

function PostCard({
  postKey,
  testid,
  feedId,
  connectionId,
  onPin,
  onUnpin,
  dashboardKey,
  isDashboardBlock = false,
  clearNotifications = false,
  hideFooterPinButton = false,
  hideHeaderPinButton = false,
  hideOverflow,
  hideBookmarkButton,
  readOnlyReactionButton,
  groovyDepths = "insideCard",
  className: customClassName,
  ...rest
}: Props) {
  if (!feedId && !dashboardKey)
    throw new Error(
      "Must pass one of either feedId or dashboardKey in order to determine whether or not the post is pinned to the current feed OR dashboard"
    )

  const classes = useStyles({
    groovyDepths,
  })
  const theme = useTheme()
  const membersLabel = useLabel("organization_member")
  const experienceLabel = useLabel("admin_experience")
  const match = useRouteMatch()
  const showOnHoverClasses = useShowOnHoverStyles()
  const isCompact = useMediaQuery(() => theme.breakpoints.down("md"))

  const post = useFragment<PostCardFragment$key>(
    graphql`
      fragment PostCardFragment on Post
      @argumentDefinitions(feedId: { type: "ID" }, dashboardId: { type: "ID" }) {
        id
        feedId
        releasedAt
        feed {
          ...usePermissionsFragment
        }
        content {
          id
          type
          ...ContentCard_ContentFragment
          ...FlagContentButtonFragment
          ...ContentFlaggingsChipFragment
          ...useCanFlagContentFragment
        }
        product {
          id
          slug
          name
          status
        }
        pinnedPost(feedId: $feedId, dashboardId: $dashboardId) {
          id
          ordering
        }
        ...usePermissionsFragment
        ...EditPostButtonFragment
        ...DeletePostButtonFragment
        ...SharePostButtonFragment
      }
    `,
    postKey
  )

  const dashboard = useFragment<PostCardDashboardFragment$key>(
    graphql`
      fragment PostCardDashboardFragment on Dashboard {
        id
        ...usePermissionsFragment
      }
    `,
    dashboardKey || null
  )

  const postPermissions = usePermissions(post)
  const feedPermissions = usePermissions(post.feed)
  const dashboardPermissions = usePermissions(dashboard)
  const canPin = dashboard
    ? dashboardPermissions.has("posts.pin")
    : feedPermissions.has("posts.pin")
  const canFlag = useCanFlagContent(post.content)
  const drawer = useGlobalDrawer("post")

  useDismissNotifications(
    {
      entityId: post?.content?.id,
      kind: "course-content-publish",
    },
    {
      skip: !clearNotifications,
    }
  )

  const appTheme = useTheme()

  if (!post) return null

  const isReleased = !post.releasedAt || new Date(post.releasedAt) <= new Date()
  const isProductInDraftMode = post.product?.status === "draft"
  const isViewingCommunityHome = match.path === ROUTE_NAMES.COMMUNITY.HOME.ROOT

  const showPin = isReleased && canPin
  const showShare = postPermissions.has("post.share")
  const showEdit = postPermissions.has("post.edit")
  const showDelete = postPermissions.has("post.delete")
  const showOverflow =
    !hideOverflow && (canFlag || showPin || showShare || showEdit || showDelete)

  if (isProductInDraftMode && isViewingCommunityHome && !canPin) return null

  return (
    <ContentCard
      testid={`${testid}.PostCard`}
      onCommentButtonClick={handleCommentButtonClick}
      contentKey={post.content}
      onClick={openPostDrawer}
      className={classNames(showOnHoverClasses.hoverable, customClassName, {
        [classes.dashboardBlock]: isDashboardBlock,
      })}
      classes={{ footerContainer: classes.footerContainer }}
      readOnlyReactionButton={readOnlyReactionButton}
      bookmarkKind={hideBookmarkButton ? undefined : "post"}
      overflow={
        showOverflow && (
          <DiscoMoreActionsDropdown
            testid={`${testid}.PostCard`}
            rotateIcon
            width={40}
            height={40}
            moreActionsButtonClassName={showOnHoverClasses.showable}
          >
            {showPin &&
              (post.pinnedPost ? (
                // Unpin post
                <UnpinPostButton pinnedPostId={post.pinnedPost.id} refetch={onUnpin}>
                  {(props) => (
                    <DiscoDropdownItem
                      title={"Unpin"}
                      icon={<DiscoIcon icon={"pin"} active />}
                      {...props}
                      testid={`${testid}.unpin`}
                    />
                  )}
                </UnpinPostButton>
              ) : (
                // Pin post
                <PinPostButton
                  postId={post.id}
                  feedId={feedId}
                  dashboardId={dashboard?.id}
                  refetch={onPin}
                >
                  {(props) => (
                    <DiscoDropdownItem
                      title={"Pin"}
                      icon={"pin"}
                      {...props}
                      testid={`${testid}.pin`}
                    />
                  )}
                </PinPostButton>
              ))}

            {showShare && (
              <SharePostButton postKey={post}>
                {(props) => (
                  <DiscoDropdownItem
                    title={"Share"}
                    icon={"send"}
                    {...props}
                    testid={`${testid}.share`}
                  />
                )}
              </SharePostButton>
            )}

            {showEdit && (
              <EditPostButton postKey={post}>
                {(props) => (
                  <DiscoDropdownItem
                    title={"Edit"}
                    icon={"pencil"}
                    {...props}
                    testid={`${testid}.edit`}
                  />
                )}
              </EditPostButton>
            )}

            {showDelete && (
              <DeletePostButton postKey={post} connectionId={connectionId}>
                {(props) => (
                  <DiscoDropdownItem
                    title={"Delete"}
                    icon={"trash"}
                    {...props}
                    testid={`${testid}.delete`}
                  />
                )}
              </DeletePostButton>
            )}

            {canFlag && (
              <FlagContentButton contentKey={post.content}>
                {(props) => (
                  <DiscoDropdownItem
                    title={"Flag"}
                    icon={"flag"}
                    {...props}
                    testid={`${testid}.flag`}
                  />
                )}
              </FlagContentButton>
            )}
          </DiscoMoreActionsDropdown>
        )
      }
      banner={
        isViewingCommunityHome &&
        isProductInDraftMode && (
          <div className={classes.bannerContainer}>
            <div className={classes.bannerWarningContainer}>
              <DiscoIcon icon={"info"} color={appTheme.palette.groovy.blue[600]} />
              <DiscoText color={"groovy.blue.700"} variant={"body-sm"} align={"left"}>
                {`Only admins will see this post because ${
                  post.product!.name
                } is in Draft mode. `}
                <DiscoLink
                  to={{
                    search: setSearchParams<GlobalDrawerParams<"experienceSettings">>(
                      location.search,
                      {
                        drawerExperienceId: post.product.id,
                        experienceSettingsTab: "details",
                      }
                    ),
                  }}
                  onClick={(e) => e.stopPropagation()}
                  textVariant={"body-sm-600"}
                >
                  {"Publish"}
                </DiscoLink>
                {` the ${experienceLabel.singular} to make this visible to all ${membersLabel.plural}.`}
              </DiscoText>
            </div>
          </div>
        )
      }
      headerRightContent={
        hideHeaderPinButton ? (
          <ContentFlaggingsChip contentKey={post.content} testid={testid} />
        ) : showPin ? (
          post.pinnedPost ? (
            <UnpinPostButton pinnedPostId={post.pinnedPost.id} refetch={onUnpin}>
              {(props) => (
                <DiscoButton
                  {...props}
                  leftIcon={<DiscoIcon icon={"pin"} active />}
                  color={"transparent"}
                  className={classes.unpinIcon}
                >
                  {isCompact ? "" : "Unpin"}
                </DiscoButton>
              )}
            </UnpinPostButton>
          ) : (
            // Pin
            <PinPostButton
              postId={post.id}
              feedId={feedId}
              dashboardId={dashboard?.id}
              refetch={onPin}
            >
              {(props) => (
                <DiscoButton
                  {...props}
                  leftIcon={<DiscoIcon icon={"pin"} />}
                  color={"transparent"}
                  className={classNames(classes.pinIcon, showOnHoverClasses.showable)}
                >
                  {isCompact ? "" : "Pin"}
                </DiscoButton>
              )}
            </PinPostButton>
          )
        ) : (
          post.pinnedPost && (
            <div className={classes.pinnedPostMarker}>
              <DiscoIcon color={theme.palette.primary.main} icon={"pin"} active />
              {!isCompact && (
                <DiscoText variant={"body-sm-600"} color={"primary.main"}>
                  {"Pinned"}
                </DiscoText>
              )}
            </div>
          )
        )
      }
      footerRightContent={
        <div className={classes.footerRightContent}>
          {/* Pin */}
          {post.pinnedPost && !hideFooterPinButton && (
            <div className={classes.pinnedPostMarker}>
              <DiscoIcon color={theme.palette.primary.main} icon={"pin"} active />
              <DiscoText variant={"body-sm-600"} color={"primary.main"}>
                {"Pinned"}
              </DiscoText>
            </div>
          )}
        </div>
      }
      {...rest}
    />
  )

  function openPostDrawer() {
    drawer.open({ postId: post.id })
  }
  function handleCommentButtonClick() {
    drawer.open({ postId: post.id, scrollToComments: "true" })
  }
}

type StyleProps = {
  groovyDepths?: Exclude<keyof Theme["palette"]["groovyDepths"], "raisedBoxShadow">
}

const useStyles = makeUseStyles((theme) => ({
  pinnedPostMarker: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  pinIcon: {
    padding: 0,
    height: "unset",
    "& svg, span": {
      color: theme.palette.text.disabled,
    },
  },
  unpinIcon: {
    padding: 0,
    height: "unset",
    "& svg, span": {
      color: theme.palette.primary.main,
    },
  },
  bannerContainer: {
    backgroundColor: theme.palette.groovy.blue[100],
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(2),
    display: "flex",
    marginBottom: theme.spacing(2),
  },
  bannerWarningContainer: {
    display: "grid",
    gridTemplateColumns: "24px 1fr",
    alignItems: "start",
    gap: theme.spacing(1),
  },
  footerContainer: {
    marginTop: "auto",
  },
  footerRightContent: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    minHeight: "36px",
  },
  dashboardBlock: ({ groovyDepths }: StyleProps) => ({
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: groovyDepths
      ? theme.palette.groovyDepths[groovyDepths]
      : theme.palette.groovyDepths.insideCard,
    border: theme.palette.constants.borderSmall,
    "&:hover": {
      boxShadow: theme.palette.groovyDepths.boxShadow,
    },
  }),
}))

export function PostCardSkeleton() {
  const theme = useTheme()
  return (
    <div
      style={{
        width: "100%",
        boxShadow: theme.palette.groovyDepths.insideCard,
        borderRadius: theme.measure.borderRadius.big,
        margin: theme.spacing(0.5),
      }}
    >
      <ContentCardSkeleton />
    </div>
  )
}

export default Relay.withSkeleton({
  component: PostCard,
  skeleton: PostCardSkeleton,
})
