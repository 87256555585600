import { EmbeddingSourceEntity } from "@/content/ai/__generated__/AIReferencesListEmbeddingSourceSelect_PaginationQuery.graphql"
import AIFormReferencesListItem from "@/content/ai/AIFormReferencesListItem"
import AIFormUploadedReferencesList from "@/content/ai/AIFormUploadedReferencesList"
import AIReferencesListEmbeddingSourceSelect from "@/content/ai/AIReferencesListEmbeddingSourceSelect"
import { GenerateCurriculumWithAIFormState } from "@/content/ai/button/GenerateCurriculumWithAIButton"
import FormStore from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import AIBookIcon from "@assets/disco/icons/ai/ai-book.svg"
import AIDocumentTextIcon from "@assets/disco/icons/ai/ai-document-text.svg"
import AIElementIcon from "@assets/disco/icons/ai/ai-element.svg"
import AIGlobeIcon from "@assets/disco/icons/ai/ai-globe.svg"
import AILinkIcon from "@assets/disco/icons/ai/ai-link.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import FileDropzone, {
  AI_GENERATION_REFERENCE_TYPES,
} from "@components/dropzone/FileDropzone"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoDivider, DiscoLinkInput, DiscoSwitch } from "@disco-ui"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import useHasEntitlement from "@utils/hook/useHasEntitlement"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"

type AIGenerationReferenceKinds = "url" | "upload" | "web_search" | EmbeddingSourceEntity

export interface AIFormReferencesListProps extends TestIDProps {
  form?: FormStore<GenerateCurriculumWithAIFormState>
  hideReferenceKinds?: AIGenerationReferenceKinds[]
  referenceUrl: string
  referenceUrlErrorMessages?: string[]
  onReferenceUrlChange: (referenceUrl: string) => void
  assetEmbeddingSourceIds: GlobalID[]
  assetEmbeddingSourceErrorMessages?: string[]
  onAssetEmbeddingSourceIdsSelect: (ids: GlobalID[]) => void
  contentEmbeddingSourceIds: GlobalID[]
  contentEmbeddingSourceErrorMessages?: string[]
  onContentEmbeddingSourceIdsSelect: (ids: GlobalID[]) => void
  trainingDataEmbeddingSourceIds: GlobalID[]
  trainingDataEmbeddingSourceErrorMessages?: string[]
  onTrainingDataEmbeddingSourceIdsSelect: (ids: GlobalID[]) => void
  moduleContentUsageId?: GlobalID
  shouldReferenceContentModule: boolean
  setShouldReferenceContentModule: React.Dispatch<React.SetStateAction<boolean>>
  uploadedAssetIds?: GlobalID[]
  onAssetUpload?: (media: MediaResult) => void
  onUploadedAssetRemove?: (media: MediaResult) => void
  shouldSearchWeb?: boolean
  setShouldSearchWeb?: (v: boolean) => void
}

function AIFormReferencesList({
  testid,
  form,
  hideReferenceKinds,
  referenceUrl,
  referenceUrlErrorMessages,
  onReferenceUrlChange,
  assetEmbeddingSourceIds,
  assetEmbeddingSourceErrorMessages,
  onAssetEmbeddingSourceIdsSelect,
  contentEmbeddingSourceIds,
  contentEmbeddingSourceErrorMessages,
  onContentEmbeddingSourceIdsSelect,
  trainingDataEmbeddingSourceIds,
  trainingDataEmbeddingSourceErrorMessages,
  onTrainingDataEmbeddingSourceIdsSelect,
  moduleContentUsageId,
  shouldReferenceContentModule,
  setShouldReferenceContentModule,
  onAssetUpload,
  onUploadedAssetRemove,
  shouldSearchWeb,
  setShouldSearchWeb,
}: AIFormReferencesListProps) {
  const classes = useStyles()
  const hasEntitlement = useHasEntitlement("ai_content_generation")
  const { curriculumGeneration } = useFeatureFlags()

  /** If they have access to an embedding, they'll be able to select it as a reference */
  const [hideMedia, setHideMedia] = useState<boolean>(false)
  const [hideContent, setHideContent] = useState<boolean>(false)
  const [hideTrainingSource, setHideTrainingSource] = useState<boolean>(false)

  return (
    <div className={classes.container}>
      {hideReferenceKinds?.includes("upload") ? null : (
        <>
          <FileDropzone
            dropzoneOptions={{ accept: AI_GENERATION_REFERENCE_TYPES }}
            onUpload={(r) => onAssetUpload?.(r)}
            onMediaSelect={(r) => onAssetUpload?.(r)}
            allowedMimeTypes={["video/mp4", "application/pdf"]}
            allowedFileTypes={["video", "document"]}
          />
          {form && onUploadedAssetRemove && (
            <AIFormUploadedReferencesList
              form={form}
              onUploadedAssetRemove={onUploadedAssetRemove}
            />
          )}
          <DiscoDivider centerText={"or"} marginBottom={2} marginTop={2} />
        </>
      )}

      {hideReferenceKinds?.includes("url") ? null : (
        <AIFormReferencesListItem
          icon={<AILinkIcon height={16} width={16} />}
          name={"Link"}
          errorMessages={referenceUrlErrorMessages}
          input={
            <DiscoLinkInput
              data-testid={`${testid}.link-input`}
              value={referenceUrl}
              onChange={(val) => onReferenceUrlChange(val)}
              disabled={!hasEntitlement}
              fullWidth
            />
          }
        />
      )}

      {hideReferenceKinds?.includes("asset") ? null : (
        <AIFormReferencesListItem
          hide={hideMedia}
          icon={<AIElementIcon height={16} width={16} />}
          name={"Media"}
          errorMessages={assetEmbeddingSourceErrorMessages}
          input={
            <AIReferencesListEmbeddingSourceSelect
              entity={"asset"}
              values={assetEmbeddingSourceIds}
              onSelect={handleAssetSelect}
              onOptionsLoaded={(options) => handleOptionsLoad(options, setHideMedia)}
            />
          }
        />
      )}

      {hideReferenceKinds?.includes("content") ? null : (
        <AIFormReferencesListItem
          hide={hideContent}
          icon={<AIDocumentTextIcon height={16} width={16} />}
          name={"Content"}
          errorMessages={contentEmbeddingSourceErrorMessages}
          input={
            <AIReferencesListEmbeddingSourceSelect
              entity={"content"}
              values={contentEmbeddingSourceIds}
              onSelect={handleContentSelect}
              onOptionsLoaded={(options) => handleOptionsLoad(options, setHideContent)}
            />
          }
        />
      )}

      {hideReferenceKinds?.includes("training_data") ? null : (
        <AIFormReferencesListItem
          hide={hideTrainingSource}
          icon={<AIBookIcon height={16} width={16} />}
          name={"Training"}
          errorMessages={trainingDataEmbeddingSourceErrorMessages}
          input={
            <AIReferencesListEmbeddingSourceSelect
              entity={"training_data"}
              values={trainingDataEmbeddingSourceIds}
              onSelect={handleTrainingSourceSelect}
              onOptionsLoaded={(options) =>
                handleOptionsLoad(options, setHideTrainingSource)
              }
            />
          }
        />
      )}

      {moduleContentUsageId && (
        <DiscoSwitch
          className={classes.moduleReferenceSwitch}
          label={"Use module content as reference"}
          checked={shouldReferenceContentModule}
          onChange={handleToggleShouldReferenceContentModule}
        />
      )}

      {curriculumGeneration &&
        (hideReferenceKinds?.includes("web_search") ? null : (
          <AIFormReferencesListItem
            hide={!curriculumGeneration}
            icon={<AIGlobeIcon height={16} width={16} />}
            name={"Web Search"}
            classes={{ rhs: classes.rhs }}
            input={
              <div className={classes.switch}>
                <DiscoSwitch
                  checked={shouldSearchWeb}
                  onChange={setShouldSearchWeb}
                  label={"Use sources from a web search when generating content"}
                  labelPlacement={"end"}
                />
              </div>
            }
          />
        ))}
    </div>
  )

  function handleToggleShouldReferenceContentModule(v: boolean) {
    setShouldReferenceContentModule(v)
  }

  function handleOptionsLoad(
    options: any[],
    setHide: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    setHide(!options.length)
  }

  function handleAssetSelect(values: GlobalID[]) {
    onAssetEmbeddingSourceIdsSelect(values)
  }

  function handleContentSelect(values: GlobalID[]) {
    onContentEmbeddingSourceIdsSelect(values)
  }

  function handleTrainingSourceSelect(values: GlobalID[]) {
    onTrainingDataEmbeddingSourceIdsSelect(values)
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "grid",
    gap: theme.spacing(1),
    gridTemplateColumns: "1fr",
  },
  moduleReferenceSwitch: {
    marginTop: theme.spacing(2),
  },
  rhs: {
    backgroundColor: "transparent",
    height: "100%",
    display: "flex",
  },
  switch: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}))

export default AIFormReferencesList
