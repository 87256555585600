import AIFormReferencesList from "@/content/ai/AIFormReferencesList"
import { GenerateCurriculumWithAIFormState } from "@/content/ai/button/GenerateCurriculumWithAIButton"
import FormStore from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AIInput from "@components/ai/AIInput"
import Form from "@components/form/Form"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoFormControl, DiscoSection, DiscoSelect, DiscoText } from "@disco-ui"
import useHasEntitlement from "@utils/hook/useHasEntitlement"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { EXAMPLE_PROMPTS } from "./utils/CurriculumGenerationUtils"

type GenerateCurriculumWithAIFormProps = TestIDProps & {
  form: FormStore<GenerateCurriculumWithAIFormState>
}

function GenerateCurriculumWithAIForm(props: GenerateCurriculumWithAIFormProps) {
  const { form, testid = "GenerateContentWithAIForm" } = props
  const hasEntitlement = useHasEntitlement("ai_content_generation")
  const classes = useStyles()

  return (
    <DiscoSection className={classes.container}>
      <Form testid={`${testid}.form`} errorInfo={null}>
        <DiscoFormControl
          entitlement={"ai_content_generation"}
          errorMessages={form.errorsByField.prompt}
          title={"Prompt"}
          titleTooltip={{
            label: (
              <>
                <DiscoText variant={"body-xs-600"}>
                  {"What makes a good prompt?"}
                </DiscoText>
                <DiscoText variant={"body-xs"} display={"block"}>
                  {
                    "The prompt guides the AI to create the curriculum. A good prompt includes what the course is about, the target audience, learning objectives and what to include (like lessons, quizzes, assignments, etc)"
                  }
                </DiscoText>
              </>
            ),
          }}
          titleAction={
            <DiscoFormControl className={classes.examplePromptFormControl}>
              <DiscoSelect
                autoComplete={false}
                testid={`${testid}.example-prompt-select`}
                classes={{ root: classes.examplePromptSelect }}
                customSelectProps={{
                  autoWidth: true,
                  menuItemClassName: classes.examplePromptItem,
                  MenuProps: {
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "right",
                    },
                  },
                }}
                onChange={(v) => {
                  form.state.prompt = v || ""
                  evaluateReferenceMeter()
                }}
                // We don't want to the selected value
                value={""}
                placeholder={"Example Prompts"}
                disableClearable
                options={EXAMPLE_PROMPTS.map((prompt) => ({
                  title: prompt,
                  value: prompt,
                }))}
              />
            </DiscoFormControl>
          }
        >
          <AIInput
            testid={`${testid}.prompt-input`}
            value={form.state.prompt}
            onChange={handlePromptChange}
            disabled={!hasEntitlement}
            placeholder={"Describe the course curriculum you want to create..."}
            showBadge={false}
            minHeight={"125px"}
          />
        </DiscoFormControl>
        <DiscoSection groovyDepths={"insideCard"}>
          <DiscoFormControl
            label={<DiscoText variant={"body-md-600"}>{"Sources"}</DiscoText>}
            sublabel={"Add sources to guide the curriculum generation."}
            testid={"GenerateCurriculumWithAIForm.sources-form-control"}
            optional
          >
            <AIFormReferencesList
              form={form}
              referenceUrl={form.state.referenceUrl as string}
              referenceUrlErrorMessages={form.errorsByField.referenceUrl}
              hideReferenceKinds={[]}
              onReferenceUrlChange={handleReferenceUrlChange}
              assetEmbeddingSourceIds={form.state.assetEmbeddingSourceIds}
              onAssetEmbeddingSourceIdsSelect={handleAssetEmbeddingSourceIdsSelect}
              contentEmbeddingSourceIds={form.state.contentEmbeddingSourceIds}
              onContentEmbeddingSourceIdsSelect={handleContentEmbeddingSourceIdsSelect}
              trainingDataEmbeddingSourceIds={form.state.trainingDataEmbeddingSourceIds}
              onTrainingDataEmbeddingSourceIdsSelect={
                handleTrainingDataEmbeddingSourceIdsSelect
              }
              shouldReferenceContentModule={false}
              setShouldReferenceContentModule={() => false}
              shouldSearchWeb={Boolean(form.state.searchWeb)}
              setShouldSearchWeb={(v) => (form.state.searchWeb = v)}
              onAssetUpload={handleAssetUpload}
              onUploadedAssetRemove={handleRemoveUploadedAsset}
            />
          </DiscoFormControl>
        </DiscoSection>
      </Form>
    </DiscoSection>
  )

  function handleAssetEmbeddingSourceIdsSelect(v: GlobalID[]) {
    form.state.assetEmbeddingSourceIds.replace(v)
    evaluateReferenceMeter()
  }

  function handleContentEmbeddingSourceIdsSelect(v: GlobalID[]) {
    form.state.contentEmbeddingSourceIds.replace(v)
    evaluateReferenceMeter()
  }

  function handleTrainingDataEmbeddingSourceIdsSelect(v: GlobalID[]) {
    form.state.trainingDataEmbeddingSourceIds.replace(v)
    evaluateReferenceMeter()
  }

  function handleAssetUpload(r: MediaResult) {
    form.state.uploadedAssetIds?.push(r.id)
    form.state.uploadedAssets.push(r)
    evaluateReferenceMeter()
  }

  function handleReferenceUrlChange(referenceUrl: string) {
    form.state.referenceUrl = referenceUrl
    evaluateReferenceMeter()
  }

  function handleRemoveUploadedAsset(media: MediaResult) {
    form.state.uploadedAssets.replace(
      form.state.uploadedAssets.filter((asset) => asset.id !== media.id)
    )
    form.state.uploadedAssetIds!.replace(
      form.state.uploadedAssetIds!.filter((id) => id !== media.id)
    )
    evaluateReferenceMeter()
  }

  function handlePromptChange(e: React.ChangeEvent<HTMLInputElement>) {
    form.state.prompt = e.target.value || ""
    evaluateReferenceMeter()
  }

  // Evaluate the reference metric based on the number of references and the prompt
  function evaluateReferenceMeter() {
    let referenceCount = 0
    // Count the number of references
    if (form.state.referenceUrl) {
      referenceCount += 1
    }

    referenceCount +=
      form.state.assetEmbeddingSourceIds.length +
      form.state.contentEmbeddingSourceIds.length +
      form.state.trainingDataEmbeddingSourceIds.length +
      (form.state.uploadedAssetIds?.length || 0)

    let referenceMeter = 0

    // If there are no references and no prompt, the reference metric is 0%
    if (referenceCount === 0 && !form.state.prompt) {
      referenceMeter = 0
    } else if (referenceCount === 0 || form.state.prompt.length < 20) {
      // if there is any length of prompt, but no references. Or any number of references, but prompt is under 20 chars, the metric = 25%
      referenceMeter = 25
    } else if (referenceCount >= 1 && !form.state.prompt) {
      // If there is one reference and no prompt, the metric = 50%
      referenceMeter = 50
    } else if (
      referenceCount === 1 &&
      form.state.prompt &&
      form.state.prompt.length > 20
    ) {
      // If there is one reference and the prompt has at least 20 characters, metric = 75%
      referenceMeter = 75
    } else if (
      referenceCount > 1 &&
      form.state.prompt &&
      form.state.prompt.length > 100
    ) {
      // If there at least 2 references and the prompt has at least 100 characters, the metric = 100%
      referenceMeter = 100
    }
    form.state.referenceMeter = referenceMeter
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    background:
      theme.palette.type === "dark"
        ? theme.palette.aiGradient.bluePurpleDark
        : theme.palette.aiGradient.aiLight,
  },
  examplePromptFormControl: {
    width: 180,
    marginBottom: 0,
    padding: 0,
  },
  examplePromptItem: {
    maxWidth: 624,
    width: 624,
    margin: theme.spacing(0, 1),
    padding: theme.spacing(1, 1.5),
    borderRadius: theme.measure.borderRadius.default,
  },
  examplePromptSelect: {
    "&, &:focus": {
      backgroundColor: theme.palette.background.paper,
    },
  },
}))

export default observer(GenerateCurriculumWithAIForm)
